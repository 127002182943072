import { IButtonStyles, ISeparatorStyles, IStackStyles, ITextStyles } from '@fluentui/react';
import { CSSProperties } from 'react';

export const scrollingCardStyles = {
  maxHeight: '50vh',
  overflowY: 'auto' as const,
  padding: '1rem',
};

export const scrollingStackStyles = {
  maxHeight: 'calc(50vh - 2rem)',
  overflowY: 'auto' as const,
};

export const boldTextStyle: ITextStyles = {
  root: {
    fontWeight: 'bold',
  },
};

export const noBackgroundStyle: CSSProperties = {
  backgroundColor: 'none',
};

export const separatorMarginStyle = (
  marginTop: string,
  marginBottom: string,
  color: string = 'lightgray'
): ISeparatorStyles => ({
  root: {
    marginTop,
    marginBottom,
    selectors: {
      '::before': {
        backgroundColor: color,
      },
    },
  },
  content: {},
});

export const stackStyles: Partial<IStackStyles> = {
  root: { height: 44 },
};

export const customButtonStyles: IButtonStyles = {
  root: {
    backgroundColor: 'transparent',
    color: 'white',
    fontSize: '18px',
  },
  rootHovered: {
    backgroundColor: 'white',
  },
};

export const textLinkCopyContainerStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
};

export const textLinkCopyTextStyle: CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const iconStyles: CSSProperties = {
  marginRight: '10px',
  color: 'black',
  fontSize: '24px',
};

export const titleTextStyles: Partial<ITextStyles> = {
  root: {
    color: '#000000',
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: '5px',
  },
};

export const messageTextStyles: Partial<ITextStyles> = {
  root: {
    color: 'black',
    fontWeight: 'bold',
    marginLeft: '5px',
  },
};

export const announcementStackStyles: Partial<IStackStyles> = {
  root: {
    backgroundColor: '#FFF4CE',
    border: '1px solid black',
    padding: '10px',
  },
};
export const userAnnouncementFormStyles: CSSProperties = {
  border: '1px solid #E1E1E1',
  padding: '20px',
  borderRadius: '5px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
};

export const cancelButtonStyles: Partial<IStackStyles> = {
  root: {
    color: '#A19F9D',
    marginRight: '-10px',
    marginTop: '-10px',
  },
};

export const approvePrimaryButtonStyles: IButtonStyles = {
  root: {
    marginTop: '50px',
    width: '160px',
    backgroundColor: '#28a745', // Green
    color: '#ffffff', // White
    border: 'none',
  },
  rootHovered: {
    backgroundColor: '#218838', // Darker green
    color: '#ffffff', // White
  },
};

export const rejectPrimaryButtonStyles: IButtonStyles = {
  root: {
    marginTop: '50px',
    width: '160px',
    backgroundColor: '#dc3545', // Red background
    color: '#ffffff', // White
    border: 'none',
  },
  rootHovered: {
    backgroundColor: '#c82333', // Darker red
    color: '#ffffff', // White
  },
};
