import React from 'react';
import { Label, Stack, Icon, Separator } from '@fluentui/react';
import { separatorMarginStyle } from '../../constants/styles';

type InputTitleProps = {
  title: string;
};

const InputTitle: React.FC<InputTitleProps> = ({ title }) => {
  return (
    <>
      <Label style={{ marginTop: '10px' }}>
        <Stack horizontal verticalAlign="center">
          <span>{title}</span>
        </Stack>
      </Label>
      <Separator styles={separatorMarginStyle('-15px', '-5px')} />
    </>
  );
};

export default InputTitle;
