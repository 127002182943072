import { ISkuType } from '../constants/interfaces';
import { tripsApi } from './tripsApi';

export const metadataApi = tripsApi.injectEndpoints({
  endpoints: (b) => ({
    getSkuTypes: b.query<ISkuType[], boolean>({
      query: (isLegacySku) => ({
        url: `/v1.1/metadata/skutypes?isLegacySku=${isLegacySku}`,
        method: 'GET',
      }),
      providesTags: ['SkuTypes'],
    }),
  }),
});

export const { useGetSkuTypesQuery } = metadataApi;
